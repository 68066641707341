<template>
    <div>
        <img class="example-headImg" src="../assets/image/aboutus/05-0banner.png"/>
        <div class="head-explain">
            <div class="head-explain-title">新闻资讯</div>
        </div>
        <div class="container-1" style="padding-top: 3rem;">
            <div v-for="item in box4List" :key="item.index">
                <div class="container4-carousel" style="border: 1px solid #EDEDED;">
                        <img :src="item.url" class="container4-carousel-img">
                        <div class="box4">
                            <div class="container4-carousel-title">{{item.title}}</div>
                            <div class="container4-carousel-date">{{item.date}}</div>
                            <div class="container4-carousel-content">{{item.content}}</div>
                            <div class="container4-carousel-more">阅读更多<i class="el-icon-right" style="margin-top:0.8rem"></i></div>
                        </div>
                    </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'BizfocusPagesPurchaseCollaboration',

    data() {
        return {
            box4List:[
                {
                    index: 1,
                    title:'梦天门科技荣获2023年软件和信息服务业社会责任治理评价A级',
                    date:'2023/12/23',
                    content:'2023年12月12日，北京软件和信息服务业协会公布了2023年《软件和信息服务业社会责任治理评价指标体系》（T/BSIA 003-2022）评价结果，梦天门科技获得A级评价。这一荣誉的获得，不仅是对梦天门科技在社会责任治理方面的高度认可，也彰显了其在软件和信息服务领域的卓越表现。',
                    url:require("../assets/image/index/企业要闻.png")
                },
                {
                    index: 2,
                    title:'江西省卫生健康监督管理工作信息化实现全覆盖',
                    date:'2023/12/12',
                    content:'江西省智慧卫监实施应用项目，2021年由省卫健委制定方案“统一组织，分期实施”，开始在省域下属机构组织落地，梦天门科技团队接受委托，首先在上饶市启动了项目试应用；2022年在鹰潭和新余两个地市正式启动应用；今年上半年在九江市、景德镇、萍乡市、南昌市、赣州市、抚州市六个地市启动应用；日前，吉安市和宜春市智慧卫监应用项目正式签约，成为江西省智慧卫监实施应用项目实现全覆盖的里程碑，为全省卫生健康数字化转型迈出重要一步。',
                    url:require("../assets/image/index/企业要闻.png")
                },
                {
                    index: 3,
                    title:'梦天门科技为国家全民健保一期监督项目提供运维保障服务',
                    date:'2023/12/01',
                    content:'2023年11月，梦天门科技公司通过单一来源采购，成功取得国家全民健保一期监督项目提供服务运维保障工作，国家全民健保一期项目在2019年由梦天门科技公司承建，如今即将进入新一轮的运维服务期。',
                    url:require("../assets/image/index/企业要闻.png")
                }
            ]
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="less" scoped>

</style>